/* src/components/VisGoogleMapComponent.css */

/* General layout for the map and floating list */
.map-layout {
    position: relative;
    width: 100%;
    height: 100vh;
  }
  
  /* Floating list positioned over the map */
  .floating-list {
    position: absolute;
    top: 10%;
    left: 20px;
    width: 300px;
    max-height: 80%;
    overflow-y: auto;
    padding: 10px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    z-index: 100; /* Ensure it is above the map */
  }
  
  /* Individual place item styling */
  .place-item {
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 10px;
    cursor: pointer;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .place-item:hover,
  .place-item.active {
    background-color: #f0f0f0; /* Highlight active or hovered item */
  }
  
  .place-thumbnail {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .place-details {
    flex-grow: 1;
  }
  
  .place-details h3 {
    margin: 0;
    font-size: 16px;
  }
  
  .place-details .place-meta {
    margin-top: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .place-meta span {
    margin-right: 10px;
  }
  
  .place-description {
    margin: 5px 0;
    font-size: 14px;
    color: #666;
  }
  
  /* Map container */
  .map-container {
    height: 100%;
  }
  
  /* Style for the custom HTML image container */
  .place-image-container {
    margin-top: -50px;
    z-index: 1000;
    width: 200px;
    height: 150px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    overflow: hidden;
    padding: 5px;
    position: absolute; /* Position relative to the map container */
    transform: translate(-50%, -100%); /* Center and move above marker */
    display: none; /* Initially hide all overlays */
  }
  
  .place-image {
    z-index: 2000;
    width: 100%; /* Make the image take up the full width of the container */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Ensure no extra space is around the image */
    border-radius: 10px;
  }
  